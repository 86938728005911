.menu-container {
    min-width: 156px;
    background-color: white;
}

#menu {
    width: 100%;
}

#menu, #menu ul {
    list-style:none;
    padding:0;
    min-width:156px;
    width: 100%;
}
#menu ul {
    position:relative;
    z-index:-1;
}
#menu li {
    position:relative;
    z-index:100;
}
#menu ul li {
    margin-top:-33px;

    -moz-transition:  0.1s linear 0.1s;
    -ms-transition: 0.1s linear 0.1s;
    -o-transition: 0.1s linear 0.1s;
    -webkit-transition: 0.1s linear 0.1s;
    transition: 0.1s linear 0.1s;

}
#menu li a {
    background-color:#ffffff;
    color:rgb(0, 0, 0);
    display:block;
    font-size:15px;
    font-weight:bold;
    line-height:50px;
    outline:0;
    padding-left:15px;
    text-decoration:none;
}

#menu li a > div {
    height: 50px; 
    display: flex; 
    align-items: center; 
    justify-content: left; 
    line-height: 20px;
    color: black;
}

.especial {
    padding-left: 15px;
    cursor: pointer;
}
.especial:hover {
    background-color:#dbdbdb;;
}
.especial:hover .icon {
    background-color:#dbdbdb;;
}
.especial:hover .especial-nombre {
    background-color:#dbdbdb;;
}

.especial-nombre {
    background-color:#ffffff;
    color:rgb(0, 0, 0);
    display:block;
    font-size:15px;
    font-weight:bold;
    line-height:50px;
    outline:0;
    padding-left:15px;
    text-decoration:none;
}

#menu li a.sub {
    background:#ffffff; /* Color Option Default */
}

/* Color menu option onhover */
#menu li a:hover {
    background-color:#dbdbdb; 
}

#menu ul li a {
    /*width: calc(100% - 2px);*/
    background-color:#ececec; /* Color Suboptions default  */
    border-bottom:1px solid #ccc;
    color:#000;
    font-size:15px;
    line-height:32px;
    padding-left: 30px;
    max-height: 32px;
}
#menu ul li a:hover {
    background-color:rgb(211, 211, 211); /* Color submenu onhover */
    color:#444;
}
.icon {
    font-size: 30px;
    position: absolute;
    margin-right: 10px;
    float:right;
    line-height:50px;
    color: rgb(104, 104, 104);
}

/* Color menu option onhover */

#menu a.sub:focus {
    background:#c2c2c2;
    outline:0;
}
#menu a:focus ~ ul li {
    margin-top:0;
/*
    -moz-transition:  0.4s linear;
    -ms-transition: 0.4s linear;
    -o-transition: 0.4s linears;
    -webkit-transition: 0.4s linears;
    transition: 0.4s linear;
*/
}

#menu a.sub:active {
    background:#888888;
    outline:0;
}
#menu a:active ~ ul li {
    margin-top:0;
}
#menu ul:hover li {
    margin-top:0;
}
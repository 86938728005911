.pedidos-ordenar {
    overflow: auto;
}

#total-productos {
    float: left;
}

#select-ordenar {
    float: right;
}
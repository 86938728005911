.pedidos-card {
    width: 258px;
    height: 378px;
    background-color: white;
    cursor: pointer;
    margin: 20px 20px 0 0;
    float: left;
}
.pedidos-card-body {
    background-color: white;
}
.pedidos-card-img-top{
    width: 100%;
}
.pedidos-card-nombre {
    font-size: 1em;
    text-align: center;
    padding: 0 5px;
}
.pedidos-card-precio {
    color: rgb(194, 0, 0);
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    padding-bottom: 10px;
}

@media (max-width: 575px) {
    .pedidos-card {
      float: initial;
      margin:20px auto;
    }
}


.listado-cabecera {
    grid-area: cabecera;
    /*background-color: #686868;*/
    border-top: 1px solid #686868;
    border-bottom: 1px solid #686868;
    width: 100%;
    height: 100%;
}
.listado-cuerpo {
    /*overflow:auto;*/
    height: calc(100vh - 145px);
    max-height: calc(100vh - 145px);
    overflow-y: auto;
}

.tabla {
    display: grid;
    grid-template-areas: 
    "cabecera"
    "cuerpo";
    grid-template-columns: 1fr;
    grid-template-rows: 2rem 1fr;
    height: 100%;
}

.tabla-cabecera {
    grid-area: cabecera;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-left: 2rem;
}
.tabla-cuerpo {
    grid-area: cuerpo;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 2rem;
    cursor: pointer;
    padding-left: 2rem;
}
.tabla-cuerpo:hover {
    background-color: #686868;
    color: white;
}

.usuarios-email {
    width: 15rem;
    text-align: left;
}
.usuarios-nombre {
    width: 7rem;
    text-align: left;
}
.usuarios-apellidos {
    width: 12rem;
    text-align: left;
}
.usuarios-socio {
    width: 10rem;
    text-align: left;
}
.usuarios-cliente {
    width: 10em;
    text-align: left;
}
.usuarios-comercial {
    width: 12rem;
    text-align: center;
}
.usuarios-activo {
    width: 3rem;
    text-align: center;
}

#ficha {
    position: absolute;
    height: calc(100vh - 5rem);
    top: 5rem;
    left: 0;
    width: 100%;
    background-color: white;
}

#cerrar-ficha {
    position: absolute;
    top: 0;
    right: 0.5em;
    font-size: 2em;
    cursor: pointer;
}

.oculto {
    display: none;
}

body {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
    /*font-size: 1rem;*/
    height: 100vh;
    margin: 0;
}

.grid-container {
    display: grid;
    grid-template-areas:
      "header"
      "main";
    grid-template-columns: 1fr;
    grid-template-rows: 4rem 1fr;
    height: 100%;
}

.header {
    grid-area: header;
}

.main {
    grid-area: main;
}

.contenedor {
    margin-top: 3.53rem;
}


.bg {
    width: 100%;
    height: 100vh;
}

.sombra {
    border-radius: 0;
    -webkit-box-shadow: 0px 1px 6px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 6px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 6px -4px rgba(0,0,0,0.75);
}

.box {
    border:1px solid #ececec;
    -webkit-box-shadow: 0px 1px 6px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 6px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 6px -4px rgba(0,0,0,0.75);
}

/* Form Slider Checkbox */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: #424242;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #424242;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }